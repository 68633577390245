@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{font-family:'Poppins', sans-serif;overflow-x:hidden}body a{color:#000;text-decoration:none !important}body h1,body h1,body h3,body h4,body h5,body h6,body p{margin:0}img{max-width:100%;height:auto}.fade-appear,.fade-enter{opacity:0;z-index:1}.fade-appear-active,.fade-enter.fade-enter-active{opacity:1;transition:opacity 300ms linear 150ms}.fade-exit{opacity:1}.fade-exit.fade-exit-active{opacity:0;transition:opacity 150ms linear}.loader{position:absolute;overflow:hidden;height:100vh;left:0;top:0;display:flex;align-items:center;justify-content:center;background:#fbfbfb}

