@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500&display=swap');



body {
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
  a {
    color: #000;
    text-decoration: none !important;
  }

  h1,
  h1,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }
}



img {
  max-width: 100%;
  height: auto;
}

// .Header {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: auto;
//   z-index: 999;

//   @media(max-width:991px) {
//     position: inherit;
//   }
// }

.fade-appear,
.fade-enter {
    opacity: 0;
    z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms linear 150ms;
}

.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 150ms linear;
}

.loader{
  position: absolute;
overflow: hidden;
height: 100vh;
left: 0;
top: 0;
display: flex;
align-items: center;
justify-content: center;
background: #fbfbfb;
}